import lotteryConfig from './lottery-config.js';

const random = function(min, max) {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}

const getRandomCard = function (currentPrize) {
  let AllPerson = JSON.parse(JSON.stringify(lotteryConfig.cardListRemainAll));
  let cardListRemainAllCopy = AllPerson.filter(e => e.ext !== 0);
  if (currentPrize.round === 3) {
    cardListRemainAllCopy = AllPerson.filter(e => e.ext === 1)
  } else if (currentPrize.round === 2) {
    cardListRemainAllCopy = AllPerson.filter(e => e.ext !== 0)
  }
  const selectCount = currentPrize.countRemain < currentPrize.everyTimeGet ? currentPrize.countRemain : currentPrize.everyTimeGet;
  // 随机抽取数据
  const selectCardList = [];

  // 正式抽奖逻辑
  for (let i = 0; i < selectCount; i++) {
    let curSelectIndex = random(0, cardListRemainAllCopy.length - 1)
    if (i === 2 && currentPrize.round === 2) {
      const ext1List = [] 
      cardListRemainAllCopy.forEach((e, ind) => {
        if (e.ext === 1) {
          ext1List.push(ind)
        }
      })
      curSelectIndex = ext1List[random(0, ext1List.length - 1)]
    }
    const card = cardListRemainAllCopy.splice(curSelectIndex, 1)[0];
    selectCardList.push(card);
  }

  // 分别统计出获奖和未获奖的名单
  lotteryConfig.cardListWinAll = [...lotteryConfig.cardListWinAll, ...selectCardList];
  lotteryConfig.cardListRemainAll = lotteryConfig.cardList.filter((item) => {
    const winItem = lotteryConfig.cardListWinAll.find(_ => _.id === item.id);
    return !winItem;
  });

  // 将当前奖项中奖人员统计
  currentPrize.cardListWin = [...currentPrize.cardListWin, ...selectCardList];
  currentPrize.countRemain = currentPrize.countRemain - selectCardList.length;
  currentPrize.round += 1;

  lotteryConfig.setLocalStorage();
  return selectCardList;
}

export { getRandomCard }
